import { Layer } from 'react-map-gl'

const paintLayer = {
  'fill-extrusion-color': '#aaa',
  'fill-extrusion-opacity': 0.35,
  'fill-extrusion-height': {
    type: 'identity' as 'identity',
    property: 'height',
  },
  'fill-extrusion-base': {
    type: 'identity' as 'identity',
    property: 'min_height',
  },
}

export default function MapBuildingsLayer() {
  return (
    <Layer
      id="3d-buildings"
      type="fill-extrusion"
      source="composite"
      source-layer="building"
      filter={['==', 'extrude', 'true']}
      minzoom={15}
      paint={paintLayer}
    />
  )
}
