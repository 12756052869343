import { useNavigation } from '@remix-run/react'
import area from '@turf/area'
import { convertArea } from '@turf/helpers'
import { LucideRadius } from 'lucide-react'
import { useMemo } from 'react'
import { aDrawnFeature, useAtomValue } from '~/atoms'
import { LoadingCircle } from '~/components'
import { cn } from '~/utils'

export default function ListingCountLoading({
  className,
  isLoading,
  count,
  totalCount,
}: {
  className?: string
  isLoading: boolean
  count: number | null
  totalCount?: number
}) {
  const navigation = useNavigation()
  const drawnFeature = useAtomValue(aDrawnFeature)

  const drawnArea = useMemo(() => {
    if (!drawnFeature) {
      return null
    }
    const squareMeters = area(drawnFeature)
    return convertArea(squareMeters, 'meters', 'miles')
  }, [drawnFeature])

  const radiusMiles = useMemo(() => {
    if (drawnFeature?.properties?.isCircle) {
      return drawnFeature.properties.radiusInKm / 1.60934
    }
    return null
  }, [drawnFeature])

  const content = useMemo(() => {
    if (isLoading || (!count && count !== 0)) {
      return (
        <span className="flex items-center">
          <LoadingCircle className="h-6 w-6 text-primary" />
          <span className="ml-2">Loading</span>
        </span>
      )
    } else if (count > 0) {
      if (totalCount) {
        return (
          <span className="flex items-center">
            {navigation.state === 'loading' && (
              <LoadingCircle className="mr-2 h-6 w-6 text-primary" />
            )}
            <span>
              {`${count.toLocaleString()} / ${totalCount.toLocaleString()}`}{' '}
              Listings
            </span>
          </span>
        )
      }
      return <span>{count.toLocaleString()} Listings</span>
    } else if (totalCount && totalCount > 0) {
      return <span>0 / {totalCount.toLocaleString()} Listings</span>
    }
    return <span>No Listings Found</span>
  }, [isLoading, count, navigation, totalCount])

  return (
    <div
      className={cn(
        'flex items-center space-x-2 divide-x divide-muted-foreground rounded-md bg-background px-4 py-2.5 text-base font-medium leading-tight shadow-md',
        className
      )}>
      {content}
      {radiusMiles && (
        <span className="inline-flex items-center pl-2">
          <LucideRadius className="mr-1 h-4 w-4" />{' '}
          <span>
            {radiusMiles.toLocaleString('en-US', {
              maximumFractionDigits: 1,
            })}{' '}
            mi
          </span>
        </span>
      )}
      {drawnArea && (
        <span className="pl-2">
          {drawnArea.toLocaleString('en-US', {
            maximumFractionDigits: 1,
          })}{' '}
          mi<sup>2</sup>
        </span>
      )}
    </div>
  )
}
