import { Link, useNavigate } from '@remix-run/react'
import {
  LucideBuilding2,
  LucideHome,
  LucideUserCircle,
  LucideZoomIn,
  LucideZoomOut,
} from 'lucide-react'
import { useMemo } from 'react'
import { useMap } from 'react-map-gl'
import {
  aMapFilters,
  aMapStyle,
  aOrganization,
  aSelectedListing,
  aUser,
  useAtom,
  useAtomValue,
  useSetAtom,
} from '~/atoms'
import { ControlTooltip } from '~/components'
import { Avatar, Button } from '~/components/ui'
import { useIsEmbed, useIsSafari, useMapCardSizes } from '~/hooks'
import { cn } from '~/utils'

export default function MapViewControls({
  className,
  isReport = false,
}: {
  className?: string
  isReport?: boolean
}) {
  const organization = useAtomValue(aOrganization)
  const user = useAtomValue(aUser)
  const setSelectedListing = useSetAtom(aSelectedListing)
  const [mapStyle, setMapStyle] = useAtom(aMapStyle)
  const mapFilters = useAtomValue(aMapFilters)
  const mapCardSizes = useMapCardSizes()
  const isEmbed = useIsEmbed()
  const mapRef = useMap()
  const navigate = useNavigate()
  const isSafari = useIsSafari()

  const setInitialView = (e: any) => {
    e.preventDefault()
    setSelectedListing(null)
    navigate('/')
    mapRef.current!.jumpTo({
      zoom: organization!.options.initialViewState.zoom,
      center: [
        organization!.options.initialViewState.longitude,
        organization!.options.initialViewState.latitude,
      ],
      // padding: { left: mapCardSizes.list, top: 0, right: 0, bottom: 0 },
      bearing: 0,
      pitch: 0,
      // duration: 500,
    })
  }

  const zoomIn = (e: any) => {
    e.preventDefault()
    mapRef.current!.flyTo({
      zoom: mapRef.current!.getZoom() + 1,
      center: mapRef.current!.getCenter(),
      padding: { left: mapCardSizes.list, top: 0, right: 0, bottom: 0 },
      bearing: 0,
      pitch: 0,
      duration: 500,
    })
  }

  const zoomOut = (e: any) => {
    e.preventDefault()
    mapRef.current!.flyTo({
      zoom: mapRef.current!.getZoom() - 1,
      center: mapRef.current!.getCenter(),
      padding: { left: mapCardSizes.list, top: 0, right: 0, bottom: 0 },
      bearing: 0,
      pitch: 0,
      duration: 500,
    })
  }

  const isBuildingToggled = useMemo(() => {
    return (
      (mapFilters.b && mapFilters.b != '0') ||
      (mapFilters.p && mapFilters.p != '0')
    )
  }, [mapFilters])

  const toggleBuildingView = (e: any) => {
    e.preventDefault()
    if (
      mapRef.current!.getPitch() !== 0 ||
      mapRef.current!.getBearing() !== 0
    ) {
      mapRef.current!.flyTo({
        zoom: mapRef.current!.getZoom(),
        center: mapRef.current!.getCenter(),
        duration: 500,
        pitch: 0,
        bearing: 0,
      })
    } else {
      setMapStyle('street')
      mapRef.current!.flyTo({
        zoom: mapRef.current!.getZoom() < 15 ? 15 : mapRef.current!.getZoom(),
        center: mapRef.current!.getCenter(),
        duration: 500,
        pitch: 45,
      })
    }
  }

  const fallback = useMemo(() => {
    if (!user) {
      return null
    }
    return [user!.firstName.slice(0, 1), user!.lastName.slice(0, 1)].join('')
  }, [user])

  return (
    <div className={className}>
      {!isReport && (
        <>
          {!isEmbed && (
            <ControlTooltip
              text={user ? 'Go to admin portal' : 'Log in'}
              side="bottom">
              <Button asChild variant="map" size="map" className="mb-2">
                <Link
                  unstable_viewTransition
                  id="map-admin"
                  className={cn(user && user.meta?.photo && 'p-0')}
                  to={user ? '/admin' : '/login'}>
                  {user ? (
                    <Avatar className="h-10 w-10 rounded-md">
                      {user!.meta?.photo && (
                        <Avatar.Image
                          src={user!.meta.photo.thumbUrl}
                          alt={`${user!.firstName} ${user!.lastName}`}
                        />
                      )}
                      <Avatar.Fallback className="rounded-none bg-transparent">
                        {fallback}
                      </Avatar.Fallback>
                    </Avatar>
                  ) : (
                    <LucideUserCircle className="h-5 w-5" />
                  )}
                </Link>
              </Button>
            </ControlTooltip>
          )}

          <ControlTooltip text="Map home">
            <Button
              id="map-home"
              variant="map"
              size="map"
              onClick={setInitialView}>
              <LucideHome className="h-5 w-5" />
            </Button>
          </ControlTooltip>
        </>
      )}

      <ControlTooltip text="Zoom in">
        <Button id="map-zoom-in" variant="map" size="map" onClick={zoomIn}>
          <LucideZoomIn className="h-5 w-5" />
        </Button>
      </ControlTooltip>
      <ControlTooltip text="Zoom out">
        <Button id="map-zoom-out" variant="map" size="map" onClick={zoomOut}>
          <LucideZoomOut className="h-5 w-5" />
        </Button>
      </ControlTooltip>
      {!isSafari && (
        <ControlTooltip text="Toggle buildings view">
          <Button
            id="map-buildings"
            variant="map"
            size="map"
            className={cn(isBuildingToggled && 'bg-primary text-white')}
            onClick={toggleBuildingView}>
            <LucideBuilding2 className="h-5 w-5" />
          </Button>
        </ControlTooltip>
      )}

      <ControlTooltip
        text={mapStyle === 'street' ? 'View satellite map' : 'View street map'}>
        <Button
          id="map-style"
          variant="map"
          size="map"
          className=""
          onClick={() => {
            setMapStyle(mapStyle === 'street' ? 'satellite' : 'street')
          }}>
          <img
            className="w-full rounded-md"
            src={mapStyle === 'street' ? '/satellite.png' : '/street.png'}
            alt={mapStyle === 'street' ? 'Select Satellite' : 'Select Street'}
          />
        </Button>
      </ControlTooltip>
    </div>
  )
}
