import { useEffect, useState } from 'react'

export default function useIsSafari() {
  const [isSafari, setIsSafari] = useState(false)
  useEffect(() => {
    setIsSafari(
      typeof navigator !== 'undefined' &&
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    )
  }, [])
  return isSafari
}
